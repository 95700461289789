import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { PowerwordButtonAlarm } from "./PowerwordButtonAlarm";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { PowerwordButtonMachineTimestamp } from "./PowerwordButtonMachineTimestamp";
import { PowerwordButtonStandard } from "./PowerwordButtonStandard";
import { PowerwordButtonTextInputTt } from "./PowerwordButtonTextInputTt";
import { PowerwordButtonStrain } from "./PowerwordButtonStrain";

export const PowerwordButtonList = () => {
  const translate = useTranslate();
  const { version, profile, powerWords } = userConfigurationStore();

  const filteredPowerwords =
    version && profile
      ? powerWords.filter(
          (powerWord) =>
            powerWord.version.includes(version) &&
            powerWord.profile.includes(profile),
        )
      : [];

  return (
    <Stack gap={2}>
      {filteredPowerwords.length > 0 ? (
        filteredPowerwords.map((powerWord) => {
          return powerWord.type === "specific-alarm" ? (
            <PowerwordButtonAlarm key={powerWord.label} powerWord={powerWord} />
          ) : powerWord.type === "machine-ts" ? (
            <PowerwordButtonMachineTimestamp
              key={powerWord.label}
              powerWord={powerWord}
            />
          ) : powerWord.type === "text-input-tt" ? (
            <PowerwordButtonTextInputTt
              key={powerWord.label}
              powerWord={powerWord}
            />
          ) : powerWord.type === "strain-input-tt" ? (
            <PowerwordButtonStrain
              key={powerWord.label}
              powerWord={powerWord}
            />
          ) : (
            <PowerwordButtonStandard
              key={powerWord.label}
              powerWord={powerWord}
            />
          );
        })
      ) : (
        <Typography>{translate("intellecta.no_power_words")}</Typography>
      )}
    </Stack>
  );
};
