import { useEffect } from "react";
import { DateTime } from "luxon";
import { DateRange, DateTimePicker } from "@mui/x-date-pickers-pro";
import { Box } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useTimeSelection } from "@/store/useTimeSelection";
import { ONE_DAY } from "@/utils/durationsInMilliseconds";
import { timezoneStore } from "@/store/useTimezoneStore";
import { dateTimeFormatStore } from "@/store/useDateTimeFormat";

export const DateTimeRangePicker = ({
  initialDates,
  maxDays,
}: {
  initialDates?: DateRange<DateTime>;
  maxDays?: number;
}) => {
  const { timezone } = timezoneStore();
  const { timeFormat } = dateTimeFormatStore();
  const translate = useTranslate();
  const { dates } = useTimeSelection();

  useEffect(() => {
    if (initialDates) {
      const [start, end] = initialDates;

      if (!start || !end) return;

      useTimeSelection.setState((prevState) => ({
        ...prevState,
        dates: {
          dateStart: start,
          dateEnd: end,
        },
      }));
    }

    if (maxDays) {
      const currentRange =
        dates.dateEnd.toMillis() - dates.dateStart.toMillis();
      const maxRange = maxDays * ONE_DAY;
      //? controllo se la differenza tra dateEnd e dateStart
      //? è maggiore rispetto a ONE_DAY * maxDays e reimposto dateEnd al max
      if (currentRange > maxRange) {
        useTimeSelection.setState((prevState) => ({
          ...prevState,
          dates: {
            ...prevState.dates,
            dateEnd: dates.dateStart.plus(maxRange),
          },
        }));
      }
    }
  }, [maxDays, dates.dateEnd, dates.dateStart, initialDates]);

  const onChangeDateStart = (newValue: DateTime | null) => {
    if (!newValue) return;

    if (maxDays) {
      const newRange = dates.dateEnd.toMillis() - newValue.toMillis();
      const maxRange = maxDays * ONE_DAY;
      //? controllo se la differenza tra dateEnd e nuovo valore
      //? è maggiore rispetto a ONE_DAY * maxDays e reimposto dateEnd al max
      if (newRange > maxRange) {
        useTimeSelection.setState((prevState) => ({
          ...prevState,
          dates: {
            dateStart: newValue,
            dateEnd: newValue.plus(maxRange),
          },
        }));
      } else {
        useTimeSelection.setState((prevState) => ({
          ...prevState,
          dates: {
            ...prevState.dates,
            dateStart: newValue,
          },
        }));
      }
    } else {
      useTimeSelection.setState((prevState) => ({
        ...prevState,
        dates: {
          ...prevState.dates,
          dateStart: newValue,
        },
      }));
    }
  };

  const onChangeDateEnd = (newValue: DateTime | null) => {
    if (!newValue) return;

    if (maxDays) {
      const newRange = newValue.toMillis() - dates.dateStart.toMillis();
      const maxRange = maxDays * ONE_DAY;
      //? controllo se la differenza tra nuovo valore e dateStart
      //? è maggiore rispetto a ONE_DAY * maxDays e reimposto dateStart al max
      if (newRange > maxRange) {
        useTimeSelection.setState((prevState) => ({
          ...prevState,
          dates: {
            dateStart: newValue.minus(maxRange),
            dateEnd: newValue,
          },
        }));
      } else {
        useTimeSelection.setState((prevState) => ({
          ...prevState,
          dates: {
            ...prevState.dates,
            dateEnd: newValue,
          },
        }));
      }
    } else {
      useTimeSelection.setState((prevState) => ({
        ...prevState,
        dates: {
          ...prevState.dates,
          dateEnd: newValue,
        },
      }));
    }
  };

  return (
    <Box display="flex" gap="1rem">
      <DateTimePicker
        label={translate("start")}
        value={dates.dateStart}
        timezone={timezone}
        maxDateTime={dates.dateEnd}
        onChange={onChangeDateStart}
        // format="dd/MM/yyyy HH:mm"
        ampm={timeFormat === "12h" ? true : false}
        disableFuture
        slotProps={{
          textField: {
            size: "small",
          },
        }}
      />
      <DateTimePicker
        label={translate("end")}
        value={dates.dateEnd}
        timezone={timezone}
        ampm={timeFormat === "12h" ? true : false}
        minDateTime={dates.dateStart}
        onChange={onChangeDateEnd}
        disableFuture
        slotProps={{
          textField: {
            size: "small",
          },
        }}
      />
    </Box>
  );
};
