import { Box } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import {
  EfficiencyPieChart,
  PieDataProps,
} from "@/components/highcharts/efficiency-piechart/EfficiencyPieChart";
import { efficiencyNavigatorColors } from "../utils/efficiencyNavigatorColors";
import { OeeMachineStatus } from "../api/useGetLongTermData";

const formatValueInObj = ({
  value,
  name,
  color,
  selected,
}: {
  value: number;
  name: string;
  color: string;
  selected?: boolean;
}): PieDataProps => {
  if (selected) {
    return {
      name: name,
      y: +value.toFixed(2),
      color: color,
      selected: selected,
    };
  }
  return {
    name: name,
    y: +value.toFixed(2),
    color: color,
  };
};

export const EfficiencyPie = ({
  title,
  data,
}: {
  title: string;
  data: OeeMachineStatus;
  type?: "percentage";
  dimension?: "small" | "large";
}) => {
  const translate = useTranslate();
  // TODO: SE SE LATO BACK-END PASSA UN ARRAY DI OGGETTI QUESTE RIGHE VERRANNO ELIMINATE
  // UTILIZZATE PER RENDERE IL CHART PIU' INDIPENDENTE
  const oee = formatValueInObj({
    name: translate("kpi.oee"),
    value: data.oee,
    color: efficiencyNavigatorColors.pie.oee,
  });
  const line = formatValueInObj({
    name: translate("losses.line_losses"),
    value: data.line,
    color: efficiencyNavigatorColors.category.line,
  });
  const process = formatValueInObj({
    name: translate("losses.process_losses"),
    value: data.process,
    color: efficiencyNavigatorColors.category.process,
  });
  const machine = formatValueInObj({
    name: translate("losses.machine_losses"),
    value: data.machine,
    color: efficiencyNavigatorColors.category.machine,
  });
  const extraCapacity = formatValueInObj({
    name: translate("losses.extra_capacity_losses"),
    value: data.extraCapacity,
    color: efficiencyNavigatorColors.pie.extraCapacity,
  });

  const offColor = formatValueInObj({
    name: "offColor",
    value: 100 - data.oee,
    color: efficiencyNavigatorColors.pie.offColor,
    selected: true,
  });
  const offColor2 = formatValueInObj({
    name: "offColor",
    value: data.oee,
    color: efficiencyNavigatorColors.pie.offColor,
    selected: true,
  });

  const firstPie = [oee, offColor];
  const secondPie = [offColor2, line, process, machine, extraCapacity];

  return (
    <Box height="100%" sx={{ aspectRatio: "1/1" }}>
      <EfficiencyPieChart
        title={title}
        firstPieData={firstPie}
        secondPieData={secondPie}
        value={oee.y}
      />
    </Box>
  );
};
