import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { IntellectaError, chat } from "../../types";
import { intellectaClient } from "../intellectaClient";
import { ONE_HOUR } from "@/utils/durationsInMilliseconds";

type Response = IntellectaError | Result;

type Payload = {
  user: string;
};

const result = z.object({
  chat_history: z.array(chat),
});

type Result = z.infer<typeof result>;

export const useGetHistoricChatList = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useQuery<Result, Error>({
    queryKey: ["intellecta-historic-chat-list", email],
    queryFn: async () => {
      const response = await intellectaClient<Payload, Response>({
        url: "/user/historic",
        payload: { user: email },
      });

      if (response === "ERROR") {
        throw new Error();
      }

      const validatedResponse = result.parse(response);
      return validatedResponse;
    },
    staleTime: ONE_HOUR,
  });
};
