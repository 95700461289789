import { useState } from "react";
import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { Card } from "@/components/Layout/Card";
import { useGetAlarmsTableData } from "./api/useGetAlarmsTableData";
import { AnalyticContainerSkeleton } from "../Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetAlarmsColumnData } from "./api/useGetAlarmsColumnData";
import { AlarmColumns } from "./components/AlarmColumns";
import { AlarmsSummaryTable } from "./components/AlarmsSummaryTable";
import { AlarmsDetailTable } from "./components/AlarmsDetailTable";
import { filterAlarmsSummaryData } from "./utils/filterAlarmsSummaryData";
import { filterAlarmsDetailData } from "./utils/filterAlarmsDetailData";

export interface AlarmsViews {
  blocking: boolean;
  warning: boolean;
  signalings: boolean;
}

export const Alarms = () => {
  const translate = useTranslate();
  const [alarmsViews, setAlarmsViews] = useState<AlarmsViews>({
    blocking: true,
    warning: true,
    signalings: true,
  });
  const {
    data: columnData,
    isLoading: columnDataLoading,
    isError: columnDataError,
  } = useGetAlarmsColumnData();
  const {
    data: streamData,
    isLoading: streamDataLoading,
    isError: streamDataError,
  } = useGetAlarmsTableData();

  const handleChangeBlocking = (showBlocking: boolean) => {
    setAlarmsViews((prevState) => ({
      ...prevState,
      blocking: showBlocking,
    }));
  };

  const handleChangeWarning = (showWarning: boolean) => {
    setAlarmsViews((prevState) => ({
      ...prevState,
      warning: showWarning,
    }));
  };

  const handleChangeSignalings = (showSignalings: boolean) => {
    setAlarmsViews((prevState) => ({
      ...prevState,
      signalings: showSignalings,
    }));
  };

  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
          unavailableShortcuts={["14d", "30d"]}
          maxDays={7}
        />
      </MachineViewNavBar>
      <Stack gap={2}>
        {columnData ? (
          <>
            <AlarmColumns
              alarmData={filterAlarmsSummaryData({
                alarmData: columnData.Result,
                alarmsViews,
              })}
              alarmsViews={alarmsViews}
              onChangeBlocking={handleChangeBlocking}
              onChangeWarning={handleChangeWarning}
              onChangeSignalings={handleChangeSignalings}
            />
            <AlarmsSummaryTable
              data={filterAlarmsSummaryData({
                alarmData: columnData.Result,
                alarmsViews,
              })}
            />
          </>
        ) : columnDataLoading ? (
          <AnalyticContainerSkeleton />
        ) : columnDataError ? (
          <Card>{translate("user_feedback.an_error_occurred")}</Card>
        ) : null}
        {streamData ? (
          <AlarmsDetailTable
            data={filterAlarmsDetailData({
              alarmData: streamData.array,
              alarmsViews,
            })}
          />
        ) : streamDataLoading ? (
          <AnalyticContainerSkeleton />
        ) : streamDataError ? (
          <Card>{translate("user_feedback.an_error_occurred")}</Card>
        ) : null}
      </Stack>
    </>
  );
};
