import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { NavButton } from "@/components/NavButton";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { ContentWrapperLayout } from "../..";
import { EfficiencyNavigatorLoader } from "../../layout/EfficiencyNavigatorLoader";
import { useGetLongTermData } from "../../api/useGetLongTermData";
import { useGetShortTermData } from "../../api/useGetShortTermData";

export const EfficiencyNavigatorLive = () => {
  const {
    data: dataLongTerm,
    error: errorLongTerm,
    isLoading: isLoadingLongTerm,
  } = useGetLongTermData();
  const {
    data: dataShortTerm,
    error: errorShortTerm,
    isLoading: isLoadingShortTerm,
  } = useGetShortTermData();
  const translate = useTranslate();

  if (errorLongTerm || errorShortTerm)
    return <Card>{translate("user_feedback.an_error_occurred")}</Card>;

  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={[
            "TwoWeeks",
            "Month",
            "ThreeMonths",
            "SixMonths",
            "Year",
            "Custom",
          ]}
        />
        <NavButton
          to="/efficiency-navigator/historic"
          text={translate("view_historic")}
        />
      </MachineViewNavBar>
      {isLoadingLongTerm || isLoadingShortTerm ? (
        <EfficiencyNavigatorLoader />
      ) : null}
      {dataLongTerm && dataShortTerm ? (
        <ContentWrapperLayout
          longTermData={dataLongTerm}
          shortTermData={dataShortTerm.data}
        />
      ) : null}
    </>
  );
};
