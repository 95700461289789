import { useState } from "react";
import {
  Stack,
  useTheme,
  styled,
  Box,
  AccordionSummary as MuiAccordionSummary,
  Accordion as MuiAccordion,
  AccordionDetails,
  Collapse,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { PowerwordButtonList } from "../Powerword/PowerwordButtonList";
import { PromptButtonList } from "../Prompt/PromptButtonList";
import { TroubleShootingMachineSelector } from "./TroubleShootingMachineSelector";
import { SentryMode } from "../SentryMode";
import { UserMenu } from "./UserMenu";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { VersionProfileMenu } from "./VersionProfileMenu";

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  borderRadius: 8,
  boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
  "&.MuiAccordion-root:first-of-type": {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  "&.MuiAccordion-root:last-of-type": {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  "&.Mui-expanded": {
    margin: 0,
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  background: `linear-gradient(to right, rgba(255,255,255,0.04), rgba(255,255,255,0.02))`,
  borderRadius: 8,
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    transition: "transform 0.3s ease",
  },
  "&.Mui-expanded .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(180deg)",
  },
  transition: "background 0.2s",
  "&:hover": {
    background: "rgba(255,255,255,0.07)",
  },
}));

export const LeftDrawer = ({
  onPromptClick,
}: {
  onPromptClick: (prompt: string) => void;
}) => {
  const [expandedPrompts, setExpandedPrompts] = useState(false);
  const [expandedPowerWords, setExpandedPowerWords] = useState(false);
  const { showBanner } = useCheckIfMessageBanner();
  const translate = useTranslate();
  const { version, services, profile } = userConfigurationStore();
  const theme = useTheme();

  return (
    <Stack
      bgcolor={theme.palette.background.paper}
      alignContent="space-between"
      height="100%"
      overflow="hidden"
      pt={showBanner ? "32px" : 0}
    >
      {services.includes("sentry") && version === "data-analysis" && (
        <SentryMode />
      )}
      <Stack p={2} flex={1} gap={2} overflow="auto">
        <Accordion
          expanded={expandedPowerWords}
          onChange={() => setExpandedPowerWords(!expandedPowerWords)}
          sx={{ margin: 0 }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            {translate("intellecta.power_word_other")}
          </AccordionSummary>
          <Collapse in={expandedPowerWords} timeout="auto" unmountOnExit>
            <AccordionDetails sx={{ p: 2 }}>
              <PowerwordButtonList />
            </AccordionDetails>
          </Collapse>
        </Accordion>
        <Accordion
          expanded={expandedPrompts}
          onChange={() => setExpandedPrompts(!expandedPrompts)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            {translate("intellecta.prompt_other")}
          </AccordionSummary>
          <Collapse in={expandedPrompts} timeout="auto" unmountOnExit>
            <AccordionDetails sx={{ p: 2 }}>
              <PromptButtonList
                onPromptClick={(prompt) => onPromptClick(prompt.prompt)}
              />
            </AccordionDetails>
          </Collapse>
        </Accordion>
        {version === "troubleshooting" && (
          <TroubleShootingMachineSelector key={`${version}-${profile}`} />
        )}
      </Stack>
      <Box p={2} display="flex" gap={1} alignItems="center">
        <UserMenu />
        <VersionProfileMenu />
      </Box>
    </Stack>
  );
};
