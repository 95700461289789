import { Stack, Typography } from "@mui/material";
import { ChatIcon } from "./ChatIcon";
import { Sender } from "../../store/chat-store";
import { useTranslate } from "@/i18n/config";

export const ChatMessageHeader = ({ sender }: { sender: Sender }) => {
  const translate = useTranslate();
  const name = sender === "User" ? translate("you") : sender;

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      alignContent="center"
      gap={1}
      p={0.5}
    >
      <ChatIcon sender={sender} />
      <Typography variant="body2">{name}</Typography>
    </Stack>
  );
};
