import { animate } from "framer-motion";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";

export function Counter({
  from,
  to,
  reverse,
}: {
  from: number;
  to: number;
  reverse: boolean;
}) {
  const [counter, setCounter] = useState(from);

  useEffect(() => {
    const controls = animate(from, to, {
      duration: .75,
      onUpdate(value) {
        setCounter(+value.toFixed(2));
      },
    });

    return () => controls.stop();
  }, [from, to]);

  if (reverse) {
    return (
      <Box
        flex={1}
        textAlign="right"
        color={counter > 0 ? "#da0050" : counter < 0 ? "#40bb45" : "white"}
      >
        {counter > 0
          ? `+${counter}%`
          : counter < 0
          ? `-${-counter}%`
          : `${counter}%`}
      </Box>
    );
  }

  return (
    <Box
      flex={1}
      textAlign="right"
      color={counter > 0 ? "#40bb45" : counter < 0 ? "#da0050" : "white"}
    >
      {counter > 0
        ? `+${counter}%`
        : counter < 0
        ? `-${-counter}%`
        : `${counter}%`}
    </Box>
  );
}
