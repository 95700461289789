import { useState } from "react";
import { Tabs, Tab, Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { DescriptiveRawData } from "./DescriptiveRawData";
import { DescriptiveAnalytics } from "./DescriptiveAnalytics";
import { ConditionMonitoring } from "./ConditionMonitoring";
import { BatchSelection } from "../../components/Common/BatchSelection";
import { RuleBreakings } from "./RuleBreakings";

const SECTIONS = {
  DESCRIPTIVE_RAW_DATA: 0,
  DESCRIPTIVE_ANALYTICS: 1,
  CONDITION_MONITORING: 2,
  RULE_BREAKINGS: 3,
};

export const Monitoring = () => {
  const [section, setSection] = useState(SECTIONS.DESCRIPTIVE_RAW_DATA);
  const translate = useTranslate();

  return (
    <>
      <Tabs
        value={section}
        aria-label="isolator monitoring tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}
      >
        <Tab
          value={SECTIONS.DESCRIPTIVE_RAW_DATA}
          label={translate("monitoring.descriptive_raw_data")}
        />
        <Tab
          value={SECTIONS.DESCRIPTIVE_ANALYTICS}
          label={translate("monitoring.descriptive_analytics")}
        />
        <Tab
          value={SECTIONS.CONDITION_MONITORING}
          label={translate("monitoring.condition_monitoring")}
        />
        <Tab
          value={SECTIONS.RULE_BREAKINGS}
          label={translate("diagnostics.lyo.rule_breakages")}
          disabled
        />
      </Tabs>
      <Stack gap={2} pt="1rem">
        <BatchSelection enableSelectAll={false} />
        {section === SECTIONS.DESCRIPTIVE_RAW_DATA && <DescriptiveRawData />}
        {section === SECTIONS.DESCRIPTIVE_ANALYTICS && <DescriptiveAnalytics />}
        {section === SECTIONS.CONDITION_MONITORING && <ConditionMonitoring />}
        {section === SECTIONS.RULE_BREAKINGS && <RuleBreakings />}
      </Stack>
    </>
  );
};
