import { Stack } from "@mui/material";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { MaterialsDetailedList } from "./components/MaterialsDetailedList";
import { MaterialsList } from "./components/MaterialsList";

export const Materials = () => {
  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
          maxDays={30}
        />
      </MachineViewNavBar>
      <Stack gap={2}>
        <MaterialsDetailedList />
        <MaterialsList />
      </Stack>
    </>
  );
};
