import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const EfficiencyNavigatorLoader = () => {
  return (
    <Stack gap={2}>
      <Grid2 container spacing={2}>
        <Grid2 lg={4} xs={12}>
          <SkeletonCard height="30vh" />
        </Grid2>
        <Grid2 lg={8} xs={12}>
          <SkeletonCard height="30vh" />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={2}>
        <Grid2 md={6} xs={12}>
          <SkeletonCard height={300} />
        </Grid2>
        <Grid2 md={6} xs={12}>
          <SkeletonCard height={300} />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={2}>
        <Grid2 md={6} xs={12}>
          <SkeletonCard height={300} />
        </Grid2>
        <Grid2 md={6} xs={12}>
          <SkeletonCard height={300} />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={2}>
        <Grid2 md={6} xs={12}>
          <SkeletonCard height={300} />
        </Grid2>
        <Grid2 md={6} xs={12}>
          <SkeletonCard height={300} />
        </Grid2>
      </Grid2>
    </Stack>
  );
};
