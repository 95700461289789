import { Stack } from "@mui/material";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { KpiSelectorProcess } from "../../components/viewer/KpiSelectorProcess";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";

export const MachinePerformanceProcess = () => {
  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
          unavailableShortcuts={["14d", "30d"]}
          maxDays={14}
        />
      </MachineViewNavBar>
      <Stack gap={2}>
        <KpiSelectorProcess />
      </Stack>
    </>
  );
};
