import { Stack } from "@mui/material";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { ChangeOverList } from "./components/ChangeOverList";
import { ChangeOverSummaryCounters } from "./components/ChangeOverSummaryCounters";
import { HomingList } from "./components/HomingList";

export const ChangeOver = () => {
  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
          maxDays={30}
        />
      </MachineViewNavBar>
      <Stack gap={2}>
        <ChangeOverList />
        <HomingList />
        <ChangeOverSummaryCounters />
      </Stack>
    </>
  );
};
