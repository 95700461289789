import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const ConfigurableLoaderLayout = () => {
  return (
    <Stack spacing={2}>
      <Box sx={{ width: "100%" }}>
        <Grid2 container columnSpacing={2} rowSpacing={2}>
          <Grid2 xs={12} md={6} lg={4} order={{ md: 1, lg: 1 }}>
            <SkeletonCard width={"100%"} height={250} />
          </Grid2>
          <Grid2 xs={12} md={12} lg={4} order={{ md: 3, lg: 1 }}>
            <SkeletonCard width={"100%"} height={250} />
          </Grid2>
          <Grid2 xs={12} md={6} lg={4} order={{ md: 1, lg: 1 }}>
            <SkeletonCard width={"100%"} height={250} />
          </Grid2>
        </Grid2>
      </Box>
      <Stack
        width={"100%"}
        direction="row"
        spacing={2}
        sx={{ justifyContent: "center" }}
        height={350}
      >
        {Array.from(Array(2)).map((_, index) => (
          <SkeletonCard key={index} width={"100%"} height={"100%"} />
        ))}
      </Stack>
      <SkeletonCard width={"100%"} height={250} />
    </Stack>
  );
};
