import { useState } from "react";
import ReactMarkdown from "react-markdown";
import type { Components } from "react-markdown";
import { motion } from "framer-motion";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
  Checkbox,
  IconButton,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import type { Theme } from "@mui/material/styles";
import { useTranslate } from "@/i18n/config";
import { useCheckIfReleaseMessage } from "@/hooks/useCheckIfReleaseMessage";

export const ReleaseMessageModal = () => {
  const translate = useTranslate();
  const { showMessage, content, dismissMessage } = useCheckIfReleaseMessage();
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const theme = useTheme<Theme>();

  if (!showMessage) {
    return null;
  }

  const handleOkClick = () => {
    dismissMessage(doNotShowAgain);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDoNotShowAgain(event.target.checked);
  };

  const cleanMarkdownForFirebase = (markdown: string) => {
    return markdown
      .replace(/\\n/g, "\n")
      .replace(/\n{3,}/g, "\n\n")
      .trim();
  };

  const CustomBulletPoint: React.FC = () => (
    <div
      style={{
        width: 6,
        height: 6,
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 8,
        flexShrink: 0,
        marginTop: 9.5,
      }}
    >
      <Check sx={{ fontSize: 6, color: "white" }} />
    </div>
  );

  // Componenti markdown con animazioni
  const markdownComponents: Partial<Components> = {
    h1: ({ children }) => (
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: "spring", stiffness: 100, damping: 10 }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ mt: 3, mb: 4 }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {children}
          </Typography>
          <Typography
            component="span"
            sx={{
              bgcolor: "#fff",
              color: theme.palette.success.contrastText,
              px: 1.5,
              py: 0.5,
              borderRadius: "20px",
              fontSize: "0.75rem",
              fontWeight: "bold",
            }}
          >
            {translate("acceptance_test.new").toUpperCase()}
          </Typography>
        </Stack>
      </motion.div>
    ),
    h3: ({ children }) => (
      <motion.div
        initial={{ x: -30, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.4, delay: 0.1 }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: theme.palette.primary.main,
            mt: 3,
            mb: 1,
            fontWeight: "bold",
            pb: 0.5,
          }}
        >
          {children}
        </Typography>
      </motion.div>
    ),
    ul: ({ children }) => (
      <motion.ul
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{ listStyle: "none", paddingLeft: "16px", marginTop: "8px" }}
      >
        {children}
      </motion.ul>
    ),
    li: ({ children }) => (
      <motion.li
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.1 }}
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "8px",
          marginBottom: "4px",
        }}
      >
        <CustomBulletPoint />
        <Typography component="span" sx={{ marginTop: "2px" }}>
          {children}
        </Typography>
      </motion.li>
    ),
    blockquote: ({ children }) => (
      <motion.div
        initial={{ x: -40, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Typography
          component="div"
          variant="body2"
          sx={{
            fontStyle: "italic",
            borderLeft: `4px solid ${theme.palette.primary.light}`,
            pl: 2,
            ml: 1,
            color: theme.palette.text.secondary,
          }}
        >
          {children}
        </Typography>
      </motion.div>
    ),
    a: ({ children, href }) => (
      <motion.a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        whileHover={{ y: -2, color: theme.palette.primary.main }}
        transition={{ duration: 0.3 }}
        style={{
          textDecoration: "underline",
          color: theme.palette.primary.dark,
        }}
      >
        {children}
      </motion.a>
    ),
  };

  return (
    <Dialog
      open={showMessage}
      onClose={handleOkClick}
      maxWidth="md"
      fullWidth
      PaperProps={{
        component: motion.div,
        initial: { opacity: 0, scale: 0.9 },
        animate: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 0.9 },
        transition: { type: "spring", stiffness: 100, damping: 15 },
        sx: { borderRadius: 2, maxHeight: "80vh", margin: 2 },
      }}
    >
      <DialogTitle
        sx={{
          py: 1.5,
          px: 3,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", lineHeight: 1 }}>
            {translate("release_notes")}
          </Typography>
          <IconButton onClick={handleOkClick} edge="end" size="small">
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ p: 3, overflowY: "auto" }}>
        <ReactMarkdown components={markdownComponents}>
          {cleanMarkdownForFirebase(content)}
        </ReactMarkdown>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          borderTop: `1px solid ${theme.palette.divider}`,
          justifyContent: "space-between",
          px: 4,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={doNotShowAgain}
              onChange={handleChange}
              color="primary"
              size="small"
            />
          }
          label={
            <Typography variant="body2" color="text.secondary">
              {translate("user_feedback.dont_show_again")}
            </Typography>
          }
        />
        <Button
          variant="contained"
          onClick={handleOkClick}
          size="small"
          sx={{ px: 3, py: 0.75 }}
        >
          {translate("actions.got_it")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
