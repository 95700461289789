import { useTranslate } from "@/i18n/config";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { GaugeWithLegend } from "./Layout/GaugeWithLegend";
import { ComparisonKpiType } from "../api/useGetConfigurableOeeData";
import { KpiInfoDifferences } from "./Layout/KpiInfoDifferences";
import { colors } from "@/styles/colors";

export const TopSection = ({
  standardAvailability,
  standardOee,
  customAvailability,
  customOee,
  standardPerformance,
  customPerformance,
  comparisonKpi,
  isChartVisible,
  onVisibilityHandle,
}: {
  standardAvailability: number;
  standardOee: number;
  customAvailability: number;
  customOee: number;
  standardPerformance: number;
  customPerformance: number;
  comparisonKpi: ComparisonKpiType;
  isChartVisible: boolean;
  onVisibilityHandle: () => void;
}) => {
  const translate = useTranslate();

  return (
    <Box sx={{ width: "100%" }}>
      <Grid2 container columnSpacing={2} rowSpacing={2}>
        <Grid2 xs={12} md={6} lg={4} order={{ md: 1, lg: 1 }}>
          <GaugeWithLegend
            value={standardOee}
            color={colors.kpi.oee}
            trackColor="#56BC7225"
            title={translate("configurable_oee.standard")}
            label={translate("kpi.oee")}
            legendList={[
              {
                label: translate("kpi.oee"),
                value: standardOee,
                color: colors.kpi.oee,
              },
              {
                label: translate("kpi.availability"),
                value: standardAvailability,
                color: colors.kpi.availability,
              },
              {
                label: translate("kpi.performance"),
                value: standardPerformance,
                color: colors.kpi.performance,
              },
            ]}
          />
        </Grid2>
        <Grid2 xs={12} md={12} lg={4} order={{ md: 3, lg: 1 }}>
          <KpiInfoDifferences
            comparisonKpi={comparisonKpi}
            onVisibilityHandle={onVisibilityHandle}
            isChartVisible={isChartVisible}
          />
        </Grid2>
        <Grid2 xs={12} md={6} lg={4} order={{ md: 1, lg: 1 }}>
          <GaugeWithLegend
            value={customOee}
            color={colors.kpi.oee}
            trackColor="#56BC7225"
            title={translate("configurable_oee.custom")}
            label={translate("kpi.oee")}
            legendList={[
              {
                label: translate("kpi.oee"),
                value: customOee,
                color: colors.kpi.oee,
              },
              {
                label: translate("kpi.availability"),
                value: customAvailability,
                color: colors.kpi.availability,
              },
              {
                label: translate("kpi.performance"),
                value: customPerformance,
                color: colors.kpi.performance,
              },
            ]}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};
