/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Stack, Typography, Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslate } from "@/i18n/config";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { Card } from "@/components/Layout/Card";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { SparklineAI } from "./SparklineAI";
import { LossesData } from "../api/useGetShortTermData";
import { Loss } from "../api/useGetLongTermData";

export const ChartItem = ({
  barChartData,
  title,
  categoryValue,
  color,
  lineChartData,
  lineChartTitle,
}: {
  barChartData: Loss[];
  title: string;
  categoryValue: number;
  color: string;
  lineChartData: LossesData;
  lineChartTitle: string;
}) => {
  const translate = useTranslate();
  const dataSorted = barChartData.sort((a, b) =>
    a.value < b.value ? 1 : b.value < a.value ? -1 : 0,
  );

  const categories = dataSorted.map((element) => element.lossName);
  const realData = dataSorted.map((element) => ({
    name: element.lossName,
    y: +element.value.toFixed(2),
    custom: {
      benchmark: +element.valueBm.toFixed(2),
      mttr: TimeHelpers.parseDurationToString({
        duration: element.mttr * 60 * 1000,
      }),
      mttrBm: TimeHelpers.parseDurationToString({
        duration: element.mttrBm * 60 * 1000,
      }),
    },
  }));

  const targetData = dataSorted.map((element) => ({
    name: element.lossName,
    y: +element.valueBm.toFixed(2),
  }));

  const benchmarksSum = targetData
    .reduce((acc, curr) => acc + curr.y, 0)
    .toFixed(2);

  return (
    <Grid2 container spacing={2}>
      <Grid2 md={6} xs={12}>
        <Card
          sx={{
            height: 300,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
              <Typography>{title}</Typography>
              <Typography sx={{ color: "#bbb", fontSize: ".6rem" }}>
                {translate("long_term")}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography color="#bbb">{translate("overall")} |</Typography>
              <Typography variant="caption" color="#bbb">
                {translate("current").toLowerCase()}
              </Typography>
              <Typography>{categoryValue.toFixed(2)}%</Typography>
              <Typography variant="caption" color="#bbb">
                {translate("benchmark").toLowerCase()}
              </Typography>
              <Typography>{benchmarksSum}%</Typography>
            </Box>
          </Stack>
          <Box sx={{ height: 240 }}>
            <BarChart.Custom
              categories={categories}
              filename={fileNameFactory({
                name: "efficiency_navigator",
              })}
              height={240}
              series={[
                {
                  name: translate("target"),
                  type: "column",
                  color: `${color}50`,
                  data: targetData,
                  // @ts-ignore
                  borderRadiusTopRight: 4,
                  borderRadiusTopLeft: 4,
                },
                {
                  name: translate("real"),
                  type: "column",
                  data: realData,
                  color: color,
                  pointPadding: 0.1,
                  pointPlacement: -0.1,
                  // @ts-ignore
                  borderRadiusTopRight: 4,
                  borderRadiusTopLeft: 4,
                },
              ]}
              uom="%"
              xAxisMax={realData.length > 5 ? 4 : undefined}
              scrollbarEnabled={realData.length > 5 ? true : false}
              tooltip={{
                enabled: true,
                options: {
                  useHTML: true,
                  formatter: function (this) {
                    const point = this.points?.[1].point as unknown as {
                      name: string;
                      custom: {
                        benchmark: number;
                        mttr: string;
                        mttrBm: string;
                      };
                    };
                    if (point) {
                      return `
                      <small>${point.name}</small><br />
                      ${translate("value")}: <b>${this.points?.[1].y}%</b><br />
                      ${translate("mttr")}: <b>${point.custom.mttr}</b><br />
                      ${translate("benchmark")}: <b>${
                        point.custom.benchmark
                      }</b><br />
                      ${translate("mttr")} ${translate("benchmark")}: <b>${
                        point.custom.mttrBm
                      }</b>
                      `;
                    }
                  },
                },
              }}
            />
          </Box>
        </Card>
      </Grid2>
      <Grid2 md={6} xs={12}>
        <SparklineAI
          lineChartData={lineChartData.chartData}
          lineChartTitle={lineChartTitle}
          benchmarkValue={lineChartData.benchmarkValue}
          latestValue={lineChartData.lastValue}
        />
      </Grid2>
    </Grid2>
  );
};
