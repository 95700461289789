import { Box, Button, List, Skeleton, Typography } from "@mui/material";
import { Modal } from "@/components/Modal";
import { useGetManualList } from "./api/useGetManualList";
import { DownloadableDocument } from "./components/DownloadableDocument";
import { toast } from "react-hot-toast";
import { useTranslate } from "@/i18n/config";
import { PictureAsPdfOutlined } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";

export const HelpModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { data, error, isLoading } = useGetManualList();

  if (error) {
    toast.error(translate("user_feedback.an_error_occurred"));
    return null;
  }

  if (!data) {
    return null;
  }

  if (Object.keys(data).length === 0) {
    toast.error(translate("user_feedback.no_documents_available"));
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={close}
      titleContent={
        <>
          <Box display="flex" gap={2} alignItems="center">
            <PictureAsPdfOutlined />
            <Typography variant="h6" fontWeight="bold">
              {translate("available_documents")}
            </Typography>
          </Box>
        </>
      }
      bodyContent={
        <>
          {isLoading ? (
            <Box alignItems="center" display="flex" gap={2}>
              <Skeleton width={200} height={60} />
              <Skeleton width={200} height={60} />
            </Box>
          ) : (
            <Grid2 container spacing={2}>
              {Object.entries(data).map(([lang, documents]) => (
                <Grid2 key={lang} md={6}>
                  <List
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {documents.map((document) => (
                      <DownloadableDocument
                        key={document.filesPath}
                        lang={lang}
                        name={document.name}
                        filesPath={document.filesPath}
                      />
                    ))}
                  </List>
                </Grid2>
              ))}
            </Grid2>
          )}
        </>
      }
      actions={
        <Button variant="outlined" onClick={close}>
          {translate("actions.cancel")}
        </Button>
      }
      maxWidth="sm"
      fullWidth
    />
  );
};
