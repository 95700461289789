import { Box, Typography } from "@mui/material";

export const LegendItem = ({
  description,
  color,
}: {
  description: string;
  color: string;
}) => {
  return (
    <Box display="flex" gap={1} alignItems="center">
      <Box width={4} height={16} bgcolor={color}></Box>
      <Typography variant="caption">{description}</Typography>
    </Box>
  );
};
