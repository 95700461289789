import { Box, Divider, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { LockOutlined } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Card } from "@/components/Layout/Card";
import { formatNumber } from "@/utils/formatNumber";
import { useTranslate } from "@/i18n/config";
import { TypewriterText } from "./TypewriterText";

export const SparklineAI = ({
  lineChartData,
  lineChartTitle,
  benchmarkValue,
  latestValue,
}: {
  lineChartData: number[][];
  lineChartTitle: string;
  benchmarkValue: number;
  latestValue: number;
}) => {
  const translate = useTranslate();
  const highestValue = Math.max(...lineChartData.map((record) => record[1]));
  const difference = benchmarkValue - latestValue;
  const color = difference >= 0 ? "green" : "red";

  return (
    <Card sx={{ height: 300, display: "flex", flexDirection: "column" }}>
      <Stack gap={1} sx={{ flex: 1 }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
          <Typography>{lineChartTitle}</Typography>
          <Typography sx={{ color: "#bbb", fontSize: ".6rem" }}>
            {translate("short_term")}
          </Typography>
        </Box>
        <Grid2 container>
          <Grid2 xs={9}>
            <LineChart.Custom
              title=""
              height={100}
              exportingEnabled={false}
              xAxisOptions={{
                lineColor: "transparent",
                gridLineColor: "transparent",
                tickColor: "transparent",
                labels: { enabled: false },
              }}
              yAxis={[
                {
                  uom: "%",
                  options: {
                    plotLines: [
                      { value: benchmarkValue, color, dashStyle: "LongDash" },
                    ],
                    lineColor: "transparent",
                    gridLineColor: "transparent",
                    tickColor: "transparent",
                    max: highestValue > 90 ? 100 : null,
                  },
                  series: [
                    {
                      type: "line",
                      data: lineChartData,
                      color,
                      name: lineChartTitle,
                    },
                  ],
                },
              ]}
              tooltip={{ enabled: true, options: { valueSuffix: "%" } }}
            />
          </Grid2>
          <Grid2 xs={3}>
            <Stack alignItems="center" justifyContent="center">
              <Typography>{latestValue.toFixed(2)}%</Typography>
              <Box
                sx={{
                  backgroundColor: color,
                  borderRadius: 2,
                  paddingX: "1rem",
                  paddingY: "0.25rem",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                >
                  {`${difference > 0 ? "-" : "+"} ${formatNumber(
                    Math.abs(difference),
                  )}%`}
                </Typography>
              </Box>
            </Stack>
          </Grid2>
        </Grid2>
        <Divider />
        {/* AI - locked */}
        <Box
          sx={{
            position: "relative",
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "not-allowed",
          }}
        >
          {/* Testo con effetto macchina da scrivere */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
              textAlign: "start", // Inizio dall'angolo in alto a sinistra
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              padding: "1rem",
              overflow: "hidden", // Evita overflow del testo
            }}
          >
            <TypewriterText
              // text="Little message of just 37 characters."
              // text="This message is just a bit longer. 49 characters."
              // text="This message is another bit longer and consists of 65 characters."
              text="This message is just a sample. This is a suggestion that Intellecta can give you to increase efficiency and improve the data monitoring experience."
              // text="This message is just a sample. This is a suggestion that Intellecta can give you to increase efficiency and improve the data monitoring experience. This is the longest message and consists of 207 characters."
              maxFontSize={36}
              minFontSize={14}
              iconSrc="/assets/img/intellecta-logo.png"
              iconAlt="Intellecta logo"
            />
          </Box>
          {/* Overlay con lucchetto */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 2,
              display: "flex",
              borderRadius: 2,
              backgroundColor: grey[700],
              paddingX: ".5rem",
              paddingY: "0.25rem",
              gap: 1,
              alignItems: "center",
            }}
          >
            <LockOutlined sx={{ color: grey[300] }} />
            <Typography variant="caption">
              AI suggestions (coming soon)
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Card>
  );
};
