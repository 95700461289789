import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { Card } from "@/components/Layout/Card";
import { NavButton } from "@/components/NavButton";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { createTheme, Stack, ThemeProvider, useTheme } from "@mui/material";
import { useGetConfigurableOeeData } from "../api/useGetConfigurableOeeData";
import { ConfigurableLoaderLayout } from "../components/Layout/LoaderLayout";
import {
  convertKpiToArray,
  customBarChartColors,
  formatValue,
} from "../utils/utilities";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";
import { TopSection } from "../components/TopSection";
import { ConfigManager } from "../components/ConfigSection/ConfigManager";
import { useEffect, useRef, useState } from "react";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";

export const ConfigurableOeeViewLive = () => {
  const translate = useTranslate();
  const globalTheme = useTheme();
  const { data, isLoading, isFetching } = useGetConfigurableOeeData();
  const { machine } = useMachineContext();
  const [isChartVisible, setIsChartVisible] = useState(false);
  const graphRef = useRef<HTMLDivElement>(null);

  const machineName = machine?.machine;

  /**
   * @description - breakpoint customization : ho applicato questa customizzazione
   * per adattare il layout dallo schermo del pc in poi
   */
  const customTheme = createTheme({
    ...globalTheme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 1000, // Personalizzazione per small breakpoint
        md: 1050, // Personalizzazione per medium breakpoint
        lg: 1400,
        xl: 1800,
      },
    },
  });

  const categoryTranslations: Record<
    | "kpi.oee"
    | "kpi.availability"
    | "kpi.performance"
    | "machine.idle_time"
    | "machine.scheduled_time"
    | "machine.not_scheduled_time",
    string
  > = {
    "kpi.oee": translate("kpi.oee"),
    "kpi.availability": translate("kpi.availability"),
    "kpi.performance": translate("kpi.performance"),
    "machine.idle_time": translate("machine.idle_time"),
    "machine.scheduled_time": translate("machine.scheduled_time"),
    "machine.not_scheduled_time": translate("machine.not_scheduled_time"),
  };

  const translatedCategories = [
    categoryTranslations["kpi.oee"],
    categoryTranslations["kpi.availability"],
    categoryTranslations["kpi.performance"],
    categoryTranslations["machine.idle_time"],
    categoryTranslations["machine.scheduled_time"],
    categoryTranslations["machine.not_scheduled_time"],
  ];

  useEffect(() => {
    if (isChartVisible && graphRef.current) {
      graphRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [isChartVisible]);

  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={[
            // "Recipe",
            // "Batch",
            "TwoWeeks",
            "Month",
            "ThreeMonths",
            "SixMonths",
            "Year",
            "Custom",
          ]}
        />
        <NavButton
          to="/configurable-oee/historic"
          text={translate("view_historic")}
        />
      </MachineViewNavBar>
      <ThemeProvider theme={customTheme}>
        {isLoading ? <ConfigurableLoaderLayout /> : null}
        {data ? (
          <Stack gap={2}>
            <TopSection
              standardAvailability={formatValue(data.standardAvailability)}
              standardOee={formatValue(data.standardOee)}
              customAvailability={formatValue(data.customAvailability)}
              customOee={formatValue(data.customOee)}
              standardPerformance={formatValue(data.standardPerformance)}
              customPerformance={formatValue(data.customPerformance)}
              comparisonKpi={data.comparisonKpi}
              onVisibilityHandle={() =>
                setIsChartVisible((prevValue) => !prevValue)
              }
              isChartVisible={isChartVisible}
            />
            <ConfigManager
              states={data.statesInfo}
              referenceOee={formatValue(data.customOee)}
              referenceAvailability={formatValue(data.customAvailability)}
              referencePerformance={formatValue(data.customPerformance)}
              referenceTotalDuration={data.totalDuration}
              isFetchingData={isFetching}
              view="live"
            />
            {isChartVisible && (
              <Card ref={graphRef}>
                <BarChart.Custom
                  title=""
                  uom="%"
                  tooltip={{
                    enabled: true,
                  }}
                  filename={fileNameFactory({
                    name: "configurable_oee",
                    machine: machineName,
                  })}
                  legend={{ enabled: false }}
                  categories={translatedCategories}
                  series={[
                    {
                      name: translate("configurable_oee.standard"),
                      type: "column",
                      data: convertKpiToArray(
                        data.comparisonKpi.standardKpi,
                      ).map((value, index) => {
                        return {
                          name: categoryTranslations[
                            value[0] as keyof typeof categoryTranslations
                          ],
                          y:
                            typeof value[1] === "string"
                              ? parseFloat(value[1])
                              : value[1],
                          color: customBarChartColors[index],
                        };
                      }),
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      borderRadiusTopRight: 4,
                      borderRadiusTopLeft: 4,
                    },
                    {
                      name: translate("configurable_oee.custom"),
                      type: "column",
                      data: convertKpiToArray(data.comparisonKpi.customKpi).map(
                        (value, index) => {
                          return {
                            name: categoryTranslations[
                              value[0] as keyof typeof categoryTranslations
                            ],
                            y:
                              typeof value[1] === "string"
                                ? parseFloat(value[1])
                                : value[1],
                            color: `${customBarChartColors[index]}50`,
                          };
                        },
                      ),
                      pointPadding: 0.1,
                      pointPlacement: -0.1,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      borderRadiusTopRight: 4,
                      borderRadiusTopLeft: 4,
                    },
                  ]}
                />
              </Card>
            )}
          </Stack>
        ) : null}
      </ThemeProvider>
    </>
  );
};
