import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Tab, Tabs, Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { useMachineContext } from "@/context/machine-context";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { Card } from "@/components/Layout/Card";
import { EfficiencyLosses } from "../../components/viewer/EfficiencyLosses";
import { KpiSelector } from "../../components/viewer/KpiSelector";
import { SpeedLosses } from "../../components/viewer/SpeedLosses";
import { SpeedSelector } from "../../components/viewer/SpeedSelector";
import { ProductSelector } from "../../components/viewer/ProductSelector";

const SECTIONS = {
  EFFICIENCY: 0,
  SPEED: 1,
  PRODUCTION: 2,
};

export const MachinePerformanceCyclic = () => {
  const { machine } = useMachineContext();
  const [section, setSection] = useState(SECTIONS.EFFICIENCY);
  const translate = useTranslate();

  if (!machine)
    return <Card>{translate("user_feedback.machine_not_found")}</Card>;

  return (
    <>
      <NavLink to="/machinerPerformance/cyclic" />
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
          unavailableShortcuts={["14d", "30d"]}
          maxDays={14}
        />
      </MachineViewNavBar>
      <Stack gap={2}>
        <Tabs
          value={section}
          aria-label="acopostrak tabs"
          onChange={(_: React.SyntheticEvent, newValue: number) => {
            setSection(newValue);
          }}
        >
          <Tab value={SECTIONS.EFFICIENCY} label={translate("efficiency")} />
          <Tab value={SECTIONS.PRODUCTION} label={translate("production")} />
          <Tab value={SECTIONS.SPEED} label={translate("speed")} />
        </Tabs>
        {section === SECTIONS.EFFICIENCY && (
          <>
            <KpiSelector />
            <EfficiencyLosses />
          </>
        )}
        {section === SECTIONS.PRODUCTION && <ProductSelector />}
        {section === SECTIONS.SPEED && (
          <>
            <SpeedSelector />
            <SpeedLosses />
          </>
        )}
      </Stack>
    </>
  );
};
