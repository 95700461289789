import { useEffect, useRef, useState } from "react";
import { Typewriter } from "react-simple-typewriter";

type TypewriterTextProps = {
  text: string;
  maxFontSize?: number;
  minFontSize?: number;
  iconSrc?: string; // Percorso dell'immagine da mostrare come icona
  iconAlt?: string; // Testo alternativo per l'immagine
};

export const TypewriterText: React.FC<TypewriterTextProps> = ({
  text,
  maxFontSize = 36,
  minFontSize = 14,
  iconSrc,
  iconAlt = "User Icon",
}) => {
  const [fontSize, setFontSize] = useState(maxFontSize);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const adjustFontSize = () => {
      if (!containerRef.current) return;

      const { width, height } = containerRef.current.getBoundingClientRect();
      const textLength = text.length;

      // Calcola dinamicamente il fontSize in base al contenitore
      const widthBasedFont = width / (textLength * 0.6); // Basato sulla larghezza
      const heightBasedFont = height * 0.5; // Basato sull'altezza
      const calculatedFontSize = Math.min(widthBasedFont, heightBasedFont);

      setFontSize(
        Math.min(maxFontSize, Math.max(minFontSize, calculatedFontSize)),
      );
    };

    adjustFontSize(); // Calcola inizialmente il fontSize
    window.addEventListener("resize", adjustFontSize);

    return () => window.removeEventListener("resize", adjustFontSize);
  }, [text, maxFontSize, minFontSize]);

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-start", // Allinea icona e testo a sinistra
        alignItems: "center", // Allinea verticalmente
        gap: "0.5rem", // Spazio tra icona e testo
        overflow: "hidden",
      }}
    >
      {/* Icona */}
      {iconSrc && (
        <img
          src={iconSrc}
          alt={iconAlt}
          style={{
            width: "2rem", // Dimensione icona
            height: "2rem",
            borderRadius: "50%", // Forma circolare
            objectFit: "cover", // Adatta l'immagine
          }}
        />
      )}

      {/* Testo con effetto macchina da scrivere */}
      <span
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: 1.2,
          whiteSpace: "normal",
        }}
      >
        <Typewriter
          words={[text]}
          loop={1}
          cursor
          cursorStyle="|"
          typeSpeed={30}
          deleteSpeed={50}
          delaySpeed={1000}
        />
      </span>
    </div>
  );
};
