import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/i18n/config";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { FetchClient } from "@/services/ApiClient";
import { ONE_SECOND } from "@/utils/durationsInMilliseconds";
import { ThingworxError } from "src/types/error";

export const aiSuggest = z.object({
  mttr: z.number(),
  mttrBm: z.number(),
  mttrGap: z.number().optional(),
  category: z.string(),
  error: z.string(),
  nEvents: z.number(),
  nEventsBm: z.number(),
  nEventsGap: z.number().optional(),
  efficiencyLost: z.number(),
  efficiency: z.number().optional(),
  efficiencyBm: z.number().optional(),
});

export type AiSuggest = z.infer<typeof aiSuggest>;

export const loss = z.object({
  valueBm: z.number(),
  nEventsBm: z.number(),
  mttr: z.number(),
  lossName: z.string(),
  mttrBm: z.number(),
  value: z.number(),
  nEvents: z.number(),
});

export type Loss = z.infer<typeof loss>;

export const oeeMachineStatus = z.object({
  process: z.number(),
  extraCapacity: z.number(),
  line: z.number(),
  machine: z.number(),
  noData: z.number(),
  oee: z.number(),
});

export type OeeMachineStatus = z.infer<typeof oeeMachineStatus>;

export const longTermDataResult = z.object({
  response: z.literal(true),
  oeeMachineStatus: oeeMachineStatus,
  lineLosses: z.array(loss),
  machineLosses: z.array(loss),
  processLosses: z.array(loss),
  aiSuggest: z.array(aiSuggest),
});

export type LongTermDataResult = z.infer<typeof longTermDataResult>;

export type LongTermDataResponse = ThingworxError | LongTermDataResult;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  language_id: string;
};

export const useGetLongTermData = () => {
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<LongTermDataResult>({
    queryKey: [
      "efficiency-navigator-long-term",
      machine!.machine,
      timeSelection,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, LongTermDataResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          timeSelection,
          language_id: language,
        },
        url: "dig.c.benchmark_thing/Services/getLongTermData",
      });
      if (!response.response) {
        throw new Error(response.errorString);
      }
      const validatedResponse = longTermDataResult.parse(response);
      return validatedResponse;
    },
    refetchInterval: ONE_SECOND * 30,
  });
};
