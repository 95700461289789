import { z } from "zod";
import { renderToString } from "react-dom/server";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { ScatterPlot } from "@/components/highcharts/scatter/Scatter";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { BasicTable } from "@/components/BasicTable";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { Card } from "@/components/Layout/Card";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { TableParser } from "@/utils/TableParser";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { AnalyticContainerSkeleton } from "../Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetJamsDetail, JamsDetail } from "./api/useGetJamsDetail";
import { useGetJamsOverview, JamOverview } from "./api/useGetJamsOverview";

export const Jams = () => {
  const { formatTimestamp } = useFormatTimestamp();
  const { data: jamsOverviews, isLoading } = useGetJamsOverview();
  const translate = useTranslate();

  const { data: jamsDetails, isLoading: loadingJamsDetails } =
    useGetJamsDetail();
  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
          maxDays={30}
        />
      </MachineViewNavBar>

      <Stack gap={2}>
        {loadingJamsDetails ? (
          <>
            <AnalyticContainerSkeleton />
            <AnalyticContainerSkeleton />
          </>
        ) : null}
        {jamsDetails ? (
          <>
            <Card>
              <ScatterPlot.Custom
                title={translate("jams")}
                scrollbarEnabled={false}
                tooltip={{
                  enabled: true,
                  options: {
                    formatter() {
                      const customParsed = z
                        .object({
                          custom: z.object({
                            description: z.string(),
                          }),
                        })
                        .safeParse(this.point);
                      if (!customParsed.success) {
                        return;
                      }

                      const custom = customParsed.data.custom;
                      return renderToString(
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                          }}
                        >
                          <b>{custom.description}</b>
                          <div>
                            <span>{translate("date")}: </span>
                            <b>
                              {this.x && typeof this.x === "number"
                                ? formatTimestamp({
                                    timestamp: this.x,
                                  })
                                : null}
                            </b>
                          </div>
                        </div>,
                      );
                    },
                  },
                }}
                yAxis={[
                  {
                    options: {
                      categories: jamsDetails.categories,
                    },

                    uom: "",
                    series: [
                      {
                        type: "scatter",
                        color: "red",
                        turboThreshold: 0,
                        data: jamsDetails.Result.map((jam) => ({
                          x: jam.Timestamp,
                          custom: {
                            description: jam.ErrorDescription,
                          },
                          y: jamsDetails.categories.findIndex(
                            (j) => j === jam.ZoneDescription,
                          ),
                        })),
                      },
                    ],
                  },
                ]}
              />
            </Card>
            <JamsDetailTable jamsDetails={jamsDetails.Result} />
          </>
        ) : null}
        {isLoading ? <AnalyticContainerSkeleton /> : null}
        {jamsOverviews ? (
          <JamsSummaryTable jamsOverviews={jamsOverviews} />
        ) : null}
      </Stack>
    </>
  );
};

function JamsDetailTable({ jamsDetails }: { jamsDetails: JamsDetail[] }) {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();

  if (jamsDetails.length === 0) {
    return <ShowEmptyData title={translate("jams.detail")} />;
  }
  const { columns, rows } = TableParser.parseData<JamsDetail>({
    data: jamsDetails,
    columnsMap: {
      Error: translate("error"),
      ErrorDescription: translate("error_description"),
      Timestamp: translate("date"),
      ZoneDescription: translate("zone_description"),
    },
    columnsOrder: ["Timestamp", "ZoneDescription", "Error", "ErrorDescription"],
    dateFormatter: formatTimestamp,
  });

  return (
    <Card>
      <Typography variant="h5" mb={2}>
        {translate("detailed_table")}
      </Typography>
      <BasicTable columns={columns} rows={rows} fileName="jams_detail_data" />
    </Card>
  );
}

function JamsSummaryTable({ jamsOverviews }: { jamsOverviews: JamOverview[] }) {
  const translate = useTranslate();
  if (jamsOverviews.length === 0) {
    return <ShowEmptyData title={translate("jams.overview")} />;
  }

  const { columns, rows } = TableParser.parseData<JamOverview>({
    data: jamsOverviews,
    columnsMap: {
      Count: "Count",
      Duration: "Duration",
      ZoneDescription: "Zone",
    },
    columnsOrder: ["ZoneDescription", "Count", "Duration"],
  });
  return (
    <Card>
      <Typography variant="h5" mb={2}>
        {translate("jams.summary_table")}
      </Typography>
      <BasicTable columns={columns} rows={rows} fileName="jams_overview_data" />
    </Card>
  );
}
