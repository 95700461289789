import {
  Box,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from "@mui/material";

const MIN = 0;

export const DualProgressBar = styled(Box)(() => ({
  position: "relative",
  height: 5,
  borderRadius: 3,
  backgroundColor: "#9e9e9e40",
  overflow: "hidden",
}));

const ProgressSegment = styled(Box)<{
  value: number;
  color: string;
  left?: string;
}>(({ value, color, left = "0%" }) => ({
  position: "absolute",
  height: "100%",
  width: `${value}%`,
  left: left,
  backgroundColor: color,
  transition: "width 0.3s ease",
}));

interface ProgressBarProps {
  referenceValue: number;
  gain: number;
  label: string;
}

export const ProgressBar = ({
  referenceValue,
  gain,
  label,
}: ProgressBarProps) => {
  const isPositiveGain = gain > 0;
  const gainWidth = Math.abs(gain);

  const getGainColor = (gainValue: number) => {
    if (gainValue > 0) return "#40bb45";
    if (gainValue < 0) return "#da0050";
    return "#ffffff";
  };

  // Calculate the actual display values
  const baseValue = referenceValue;
  const gainSegmentStart = isPositiveGain
    ? referenceValue
    : referenceValue - gainWidth;
  const gainSegmentWidth = gainWidth;

  return (
    <Box mt={-1}>
      <Box display="flex" alignItems="center" mt={1}>
        <Typography fontSize={11} sx={{ flexGrow: 1 }}>
          {label}
        </Typography>
        <Typography
          fontSize={11}
          sx={{
            color: getGainColor(gain),
            fontWeight: "medium",
          }}
        >
          {gain > 0 ? "+" : ""}
          {gain}%
        </Typography>
      </Box>
      <DualProgressBar>
        <ProgressSegment
          value={baseValue}
          color="white"
          sx={{ opacity: 0.5 }}
        />
        <ProgressSegment
          value={gainSegmentWidth}
          color={getGainColor(gain)}
          left={`${gainSegmentStart}%`}
          sx={{ opacity: 0.8 }}
        />
      </DualProgressBar>
    </Box>
  );
};

export const DurationProgressBar = ({
  duration,
  durationLabel,
  totalDuration,
  label,
}: {
  duration: number;
  durationLabel: string;
  totalDuration: number;
  label: string;
}) => {
  const MAX = totalDuration;
  const normalise = (value: number) => ((value - MIN) * 100) / (MAX - MIN);

  return (
    <Box mt={-1}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={1}
      >
        <Typography fontSize={11} sx={{ flexGrow: 1 }}>
          {label}
        </Typography>
        <Typography textAlign="right" fontSize={11} sx={{ flexGrow: 1 }}>
          {durationLabel}
        </Typography>
      </Box>
      <BorderLinearProgress variant="determinate" value={normalise(duration)} />
    </Box>
  );
};

export const ProgressBar1 = ({
  referenceValue,
  gain,
  label,
}: ProgressBarProps) => {
  const isPositiveGain = gain > 0;
  const gainWidth = Math.abs(gain);

  const getGainColor = (gainValue: number) => {
    if (gainValue > 0) return "#4caf50";
    if (gainValue < 0) return "#f44336";
    return "#ffffff";
  };

  // Calculate the actual display values
  const baseValue = referenceValue;
  const gainSegmentStart = isPositiveGain
    ? referenceValue
    : referenceValue - gainWidth;
  const gainSegmentWidth = gainWidth;

  return (
    <Box mt={-1}>
      <DualProgressBar>
        <ProgressSegment
          value={baseValue}
          color="white"
          sx={{ opacity: 0.5 }}
        />
        <ProgressSegment
          value={gainSegmentWidth}
          color={getGainColor(gain)}
          left={`${gainSegmentStart}%`}
          sx={{ opacity: 0.8 }}
        />
      </DualProgressBar>
      <Box display="flex" alignItems="center" mt={1}>
        <Typography fontSize={11} sx={{ flexGrow: 1 }}>
          {label}
        </Typography>
        <Typography
          fontSize={11}
          sx={{
            color: getGainColor(gain),
            fontWeight: "medium",
          }}
        >
          {gain > 0 ? "+" : ""}
          {gain}%
        </Typography>
      </Box>
    </Box>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: "#9e9e9e40",
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
    ...theme.applyStyles("dark", {
      backgroundColor: "#9ea0a6",
    }),
  },
}));

export const DurationProgressBar1 = ({
  duration,
  durationLabel,
  totalDuration,
}: {
  duration: number;
  durationLabel: string;
  totalDuration: number;
}) => {
  const MAX = totalDuration;
  const normalise = (value: number) => ((value - MIN) * 100) / (MAX - MIN);

  return (
    <Box mt={-1}>
      <BorderLinearProgress variant="determinate" value={normalise(duration)} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={1}
      >
        <Typography fontSize={11} sx={{ flexGrow: 1 }}>
          {"Duration"}
        </Typography>
        <Typography textAlign="right" fontSize={11} sx={{ flexGrow: 1 }}>
          {durationLabel}
        </Typography>
      </Box>
    </Box>
  );
};
