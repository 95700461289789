import { SwiperSlide } from "swiper/react";
import { Box, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslate } from "@/i18n/config";
import { AiSuggestItem } from "./components/AiSuggestItem";
import { ChartItem } from "./components/ChartItem";
import { EfficiencyPie } from "./components/EfficiencyPie";
import { efficiencyNavigatorColors } from "./utils/efficiencyNavigatorColors";
import { Carousel } from "./components/Carousel";
import { Card } from "@/components/Layout/Card";
import { formatNumber } from "@/utils/formatNumber";
import { LegendItem } from "./components/LegendItem";
import {
  AiSuggest,
  LongTermDataResult,
  Loss,
  OeeMachineStatus,
} from "./api/useGetLongTermData";
import { ShortTermData } from "./api/useGetShortTermData";

export const ContentWrapperLayout = ({
  longTermData,
  shortTermData,
}: {
  longTermData: Omit<LongTermDataResult, "response">;
  shortTermData: ShortTermData;
}) => {
  return (
    <Stack gap={2}>
      <TopRow
        aiSuggest={longTermData.aiSuggest}
        oeeMachineStatus={longTermData.oeeMachineStatus}
      ></TopRow>
      <BottomRow
        lineLossesData={longTermData.lineLosses}
        processLineData={longTermData.processLosses}
        machineLossesData={longTermData.machineLosses}
        oeeMachineStatus={longTermData.oeeMachineStatus}
        shortTermData={shortTermData}
      />
    </Stack>
  );
};

const TopRow = ({
  aiSuggest,
  oeeMachineStatus,
}: {
  aiSuggest: AiSuggest[];
  oeeMachineStatus: OeeMachineStatus;
}) => {
  const translate = useTranslate();
  const aiSuggestSorted = aiSuggest.sort((a, b) =>
    a.efficiencyLost < b.efficiencyLost
      ? 1
      : b.efficiencyLost < a.efficiencyLost
      ? -1
      : 0,
  );

  return (
    <Grid2 container spacing={2} display="flex">
      {/* pie */}
      <Grid2 lg={4} xs={12} height="30vh">
        <Card
          sx={{
            height: "100%", // Adatta al contenitore
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <EfficiencyPie title={translate("kpi.oee")} data={oeeMachineStatus} />
          <Stack spacing={1}>
            <LegendItem
              description={`${translate("kpi.oee")} - ${formatNumber(
                oeeMachineStatus.oee,
              )}%`}
              color={efficiencyNavigatorColors.pie.oee}
            />
            <LegendItem
              description={`${translate(
                "losses.machine_losses",
              )} - ${formatNumber(oeeMachineStatus.machine)}%`}
              color={efficiencyNavigatorColors.category.machine}
            />
            <LegendItem
              description={`${translate(
                "losses.process_losses",
              )} - ${formatNumber(oeeMachineStatus.process)}%`}
              color={efficiencyNavigatorColors.category.process}
            />
            <LegendItem
              description={`${translate("losses.line_losses")} - ${formatNumber(
                oeeMachineStatus.line,
              )}%`}
              color={efficiencyNavigatorColors.category.line}
            />
          </Stack>
        </Card>
      </Grid2>
      {/* carousel */}
      <Grid2
        lg={8}
        xs={12}
        sx={{
          height: "30vh",
          display: "flex",
          flexDirection: "column",
          position: "relative", // Per posizionare correttamente i dots
        }}
      >
        {aiSuggestSorted.length > 0 ? (
          <Box sx={{ height: "100%" }}>
            <Carousel
              slidesPerView={1}
              spaceBetween={20}
              loop={aiSuggestSorted.length > 1 ? true : false}
            >
              {aiSuggestSorted.map((item, index) => (
                <SwiperSlide key={`efficiency-slide-${index}`}>
                  <AiSuggestItem data={item} key={index} />
                </SwiperSlide>
              ))}
            </Carousel>
          </Box>
        ) : (
          <Card
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {translate("efficiency_navigator.no_losses_to_report")}
          </Card>
        )}
      </Grid2>
    </Grid2>
  );
};

const BottomRow = ({
  lineLossesData,
  processLineData,
  machineLossesData,
  oeeMachineStatus,
  shortTermData,
}: {
  lineLossesData: Loss[];
  processLineData: Loss[];
  machineLossesData: Loss[];
  oeeMachineStatus: OeeMachineStatus;
  shortTermData: ShortTermData;
}) => {
  const translate = useTranslate();

  return (
    <Stack gap={2}>
      <ChartItem
        title={translate("losses.machine_losses")}
        barChartData={machineLossesData}
        categoryValue={oeeMachineStatus.machine}
        color={efficiencyNavigatorColors.category.machine}
        lineChartData={shortTermData.machineLosses}
        lineChartTitle={translate("losses.machine_losses")}
      />
      <ChartItem
        title={translate("losses.process_losses")}
        barChartData={processLineData}
        categoryValue={oeeMachineStatus.process}
        color={efficiencyNavigatorColors.category.process}
        lineChartData={shortTermData.processLosses}
        lineChartTitle={translate("losses.process_losses")}
      />
      <ChartItem
        title={translate("losses.line_losses")}
        barChartData={lineLossesData}
        categoryValue={oeeMachineStatus.line}
        color={efficiencyNavigatorColors.category.line}
        lineChartData={shortTermData.lineLosses}
        lineChartTitle={translate("losses.line_losses")}
      />
    </Stack>
  );
};
