import { useState } from "react";
import { Box, Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { PhaseCard } from "../../components/Monitoring/PhaseCard";
import { useGetDescriptiveAnalyticsList } from "../../api/Monitoring/useGetDescriptiveAnalyticsList";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Phase } from "../../types";
import { DescriptiveAnalyticsSelection } from "../../components/Monitoring/DescriptiveAnalyticsSelection";
import { DescriptiveAnalyticsChart } from "../../components/Monitoring/DescriptiveAnalyticsChart";

export const DescriptiveAnalytics = () => {
  const translate = useTranslate();
  const [selectedPhase, setSelectedPhase] = useState<Phase | null>(null);
  const [selectedPhaseId, setSelectedPhaseId] = useState<number | null>(null);
  const [selectedAnalytic, setSelectedAnalytic] = useState<number | null>(null);
  const { data, isLoading, error } = useGetDescriptiveAnalyticsList();

  if (isLoading) return <SkeletonCard height={180} />;
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!data) return <Card>{translate("user_feedback.no_data")}</Card>;

  const onSelectPhase = (phase: Phase) => {
    if (phase === selectedPhase) {
      setSelectedPhase(null);
      setSelectedPhaseId(null);
    } else {
      setSelectedPhase(phase);
      setSelectedPhaseId(
        data.analytics.descriptive_analytics.find((el) => el.phase === phase)
          ?.phaseId || null,
      );
    }
    setSelectedAnalytic(null);
  };

  return (
    <Stack gap={2}>
      <Box display="flex" gap={2}>
        {data.analytics.descriptive_analytics.map(({ phase, evaluations }) => (
          <PhaseCard
            key={`${phase}-card-descriptive-analytics`}
            phase={phase}
            active={phase === selectedPhase}
            onSelectedPhase={onSelectPhase}
            goodBatches={evaluations.good}
            badBatches={evaluations.bad}
          />
        ))}
      </Box>
      {selectedPhase && (
        <Box display="flex" gap={2}>
          <DescriptiveAnalyticsSelection
            analytics={
              data.analytics.descriptive_analytics.find(
                (phase) => phase.phase === selectedPhase,
              )?.analytics ?? []
            }
            selectedAnalytic={selectedAnalytic}
            onSelectAnalytic={(value) => setSelectedAnalytic(value)}
          />
          {selectedAnalytic && selectedPhaseId && (
            <DescriptiveAnalyticsChart
              analyticId={selectedAnalytic}
              phaseId={selectedPhaseId}
            />
          )}
        </Box>
      )}
    </Stack>
  );
};
