import { useState } from "react";
import { Tabs, Tab, Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { BatchDiagnostic } from "./BatchDiagnostic";
import { SystemDiagnostic } from "./SystemDiagnostic";
import { BatchSelection } from "../../components/Common/BatchSelection";

const SECTIONS = {
  BATCH_DIAGNOSTIC: 0,
  SYSTEM_DIAGNOSTIC: 1,
};

export const Diagnostic = () => {
  const [section, setSection] = useState(SECTIONS.BATCH_DIAGNOSTIC);
  const translate = useTranslate();

  return (
    <>
      <Tabs
        value={section}
        aria-label="isolator diagnostic tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}
      >
        <Tab
          value={SECTIONS.BATCH_DIAGNOSTIC}
          label={translate("diagnostic.production_diagnostic")}
        />
        <Tab
          value={SECTIONS.SYSTEM_DIAGNOSTIC}
          label={translate("diagnostic.system_diagnostic")}
        />
      </Tabs>
      <Stack gap={2} pt="1rem">
        <BatchSelection enableSelectAll={false} />
        {section === SECTIONS.BATCH_DIAGNOSTIC && <BatchDiagnostic />}
        {section === SECTIONS.SYSTEM_DIAGNOSTIC && <SystemDiagnostic />}
      </Stack>
    </>
  );
};
