import { Fragment, useState } from "react";
import { renderToString } from "react-dom/server";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Piechart } from "@/components/highcharts/piechart/Piechart";
import { Card } from "@/components/Layout/Card";
import { BulletChart } from "@/components/highcharts/bullet/BulletChart";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Solidgauge } from "@/components/highcharts/gauge/SolidGauge";
import { LoaderCard } from "@/components/Layout/LoaderCard";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import {
  CandleChartData,
  useGetProcessingStatus,
} from "./api/useGetProcessingStatus";
import { useSetBenchmark } from "./api/useSetBenchmark";
import "./styles/custom-plotband.css";

const EfficiencyStates = () => {
  const { data, isLoading } = useGetProcessingStatus();
  if (isLoading) {
    return <LoaderCard />;
  }

  const bulletCharts = data?.data.length ? (
    data.data.map((bulletData) => (
      <Bullet key={bulletData.state} data={bulletData} />
    ))
  ) : (
    <ShowEmptyData title="" />
  );

  return (
    <Fragment>
      {data ? (
        <Stack gap={2}>
          <Grid2 container height={280}>
            <Grid2 xs={4}>
              <Stack gap={2}>
                {data.data
                  .filter((value) => value.comparisonToBm >= 0)
                  .map((value) => (
                    <ShowPercentage
                      key={value.state}
                      percentageValue={value.comparisonToBm}
                      state={value.state}
                    />
                  ))}
              </Stack>
            </Grid2>
            <Grid2 xs={4}>
              <Piechart
                data={data.pieData}
                label=""
                title=""
                version="machineDetail"
              />
            </Grid2>
            <Grid2 xs={4}>
              <Stack gap={2}>
                {data.data
                  .filter((value) => value.comparisonToBm < 0)
                  .map((value) => (
                    <ShowPercentage
                      key={value.state}
                      percentageValue={value.comparisonToBm}
                      isPositive
                      state={value.state}
                    />
                  ))}
              </Stack>
            </Grid2>
          </Grid2>

          <Stack gap={2}>{bulletCharts}</Stack>
        </Stack>
      ) : null}
    </Fragment>
  );
};

function Bullet({ data }: { data: CandleChartData }) {
  const { isOpen, open, close } = useDisclosure();
  const translate = useTranslate();
  const { avg, bm, efficiency, max, min, state, stateNumber } = data;

  const yAxisMax = max > bm ? max * 1.1 : bm * 1.1;
  const median = parseFloat(avg.toFixed(2));

  return (
    <Fragment>
      <Card
        animate={{
          opacity: 1,
        }}
        initial={{
          opacity: 0,
        }}
        exit={{
          opacity: 0,
        }}
      >
        <Stack gap={2}>
          <Stack direction="row" justifyContent={"space-between"} gap={2}>
            <Typography fontWeight="bold">{state}</Typography>
            <Button
              endIcon={<Edit />}
              variant="outlined"
              onClick={() => open()}
              sx={{ width: "fit-content", alignSelf: "end" }}
            >
              {translate("efficiency_states.set_benchmark")}
            </Button>
          </Stack>
          <Stack direction="row" height={160} alignItems="center">
            <Box width="80%" height={80}>
              <BulletChart.Custom
                title={""}
                legend={{
                  enabled: false,
                }}
                tooltip={{
                  enabled: true,
                  options: {
                    formatter: function () {
                      const med = TimeHelpers.parseDurationToString({
                        duration: median * 1000,
                        fmt: "hh:mm:ss",
                      });

                      const minValue = TimeHelpers.parseDurationToString({
                        duration: min * 1000,
                        fmt: "hh:mm:ss",
                      });

                      const maxValue = TimeHelpers.parseDurationToString({
                        duration: max * 1000,
                        fmt: "hh:mm:ss",
                      });

                      const bmValue = TimeHelpers.parseDurationToString({
                        duration: bm * 1000,
                        fmt: "hh:mm:ss",
                      });

                      return renderToString(
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                          }}
                        >
                          <div>
                            {translate("math.median")}: <b>{med}</b>
                          </div>
                          <div>
                            {translate("target")}: <b>{bmValue}</b>
                          </div>
                          <div>
                            {translate("math.minimum")}: <b>{minValue}</b>
                          </div>
                          <div>
                            {translate("math.maximum")}: <b>{maxValue}</b>
                          </div>
                        </div>,
                      );
                    },
                    outside: true,
                  },
                }}
                annotation={{
                  enabled: true,
                  options: {
                    draggable: "",
                    labels: [
                      {
                        point: {
                          x: 0,
                          y: median,
                          xAxis: 1,
                          yAxis: 0,
                        },
                        shape: "connector",
                        style: {
                          color: "white",
                        },
                        text: "avg",
                      },
                      {
                        point: {
                          x: 0,
                          y: bm,
                          xAxis: 1,
                          yAxis: 0,
                        },
                        shape: "connector",
                        style: {
                          color: "white",
                        },
                        text: "bm",
                      },
                    ],
                  },
                }}
                yAxis={{
                  options: {
                    max: yAxisMax,
                    tickInterval: Math.floor(yAxisMax / 10),
                    labels: {
                      formatter: function (value) {
                        const time = TimeHelpers.parseDurationToString({
                          duration: value.pos * 1000,
                        });
                        return `<h1 style=color:white>${time}</h1>`;
                      },
                    },

                    plotBands: [
                      {
                        from: min,
                        to: max,
                        className: "customPlotBand",
                        color: {
                          linearGradient: { x1: 0, x2: 1, y1: 1, y2: 1 },
                          stops: [
                            [0, "#004754"],
                            [1, "#405C5F"],
                          ],
                        },
                      },
                    ],
                  },
                  series: [
                    {
                      type: "bullet",
                      data: [
                        {
                          y: median,
                          color: "#aaa",
                          target: bm,
                          borderColor: "red",
                          targetOptions: { color: "#FFd700", width: 20 },
                        },
                      ],
                    },
                  ],
                }}
              />
            </Box>
            <Box width="20%" height={160}>
              <Box height={"100%"} position="relative">
                <Typography
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  variant="subtitle1"
                >
                  {efficiency.toFixed(2)}%
                </Typography>
                <Solidgauge.Custom
                  title=""
                  plotOptions={{
                    solidgauge: {
                      innerRadius: "80%",
                      dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true,
                        enabled: false,
                      },
                    },
                  }}
                  legend={{
                    enabled: false,
                  }}
                  tooltip={{
                    enabled: false,
                  }}
                  yAxis={{
                    options: {
                      min: 0,
                      max: 100,
                      stops: [
                        [0.1, "#DF5353"], // red
                        [0.5, "#DDDF0D"], // yellow
                        [0.9, "#55BF3B"], // green
                      ],
                      lineWidth: 0,
                      minorTickInterval: 0,
                      tickAmount: 0,
                      labels: {
                        enabled: false,
                      },
                    },
                    series: [
                      {
                        type: "solidgauge",
                        data: [efficiency],
                        rounded: true,
                      },
                    ],
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Card>
      <Dialog
        open={isOpen}
        onClose={() => close()}
        scroll="paper"
        maxWidth="md"
      >
        <EditBenchmarkModalContent
          close={() => close()}
          state={state}
          stateNumber={stateNumber}
        />
      </Dialog>
    </Fragment>
  );
}

function EditBenchmarkModalContent({
  close,
  state,
  stateNumber,
}: {
  close: () => void;
  state: string;
  stateNumber: number;
}) {
  const [hours, setHours] = useState<number>();
  const [minutes, setMinutes] = useState<number>();
  const [seconds, setSeconds] = useState<number>();

  const { mutate: setBenchmark } = useSetBenchmark();

  const totalSeconds =
    (hours ?? 0) * 3600 + (minutes ?? 0) * 60 + (seconds ?? 0);
  const translate = useTranslate();
  return (
    <Fragment>
      <DialogTitle>{state}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Typography>
            {translate("efficiency_states.set_the_benchmark_for_the_state")}
          </Typography>

          <Stack direction="row" gap={2} justifyContent="center">
            <TextField
              sx={{
                width: 120,
              }}
              label={translate("durations.hour_other")}
              type="number"
              InputProps={{
                inputProps: {
                  itemType: "number",
                  inputMode: "numeric",
                  min: 0,
                  pattern: "[0-9]*",
                },
              }}
              value={hours}
              onChange={(e) => {
                const { value } = e.target;
                if (!value) return setHours(undefined);

                const parsedValue = parseInt(e.target.value, 10);

                setHours(parsedValue);
              }}
            />
            <TextField
              sx={{
                width: 120,
              }}
              type="number"
              label={translate("durations.minute_other")}
              InputProps={{
                inputProps: {
                  itemType: "number",
                  inputMode: "numeric",
                  min: 0,
                  max: 59,
                  pattern: "[0-9]*",
                },
              }}
              value={minutes}
              onChange={(e) => {
                const { value } = e.target;
                if (!value) return setMinutes(undefined);

                const parsedValue = parseInt(e.target.value, 10);
                if (parsedValue > 59) return;
                setMinutes(parsedValue);
              }}
            />
            <TextField
              sx={{
                width: 120,
              }}
              type="number"
              label={translate("durations.second_other")}
              InputProps={{
                inputProps: {
                  itemType: "number",
                  inputMode: "numeric",
                  min: 0,
                  max: 59,
                  pattern: "[0-9]*",
                },
              }}
              value={seconds}
              onChange={(e) => {
                const { value } = e.target;
                if (!value) return setSeconds(undefined);
                const parsedValue = parseInt(e.target.value, 10);
                if (parsedValue > 59) return;
                setSeconds(parsedValue);
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={() => close()}>
          {translate("actions.cancel")}
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            setBenchmark(
              { state: stateNumber, newBenchmark: totalSeconds },
              {
                onSuccess: () => {
                  close();
                },
              },
            );
          }}
        >
          {translate("actions.confirm")}
        </Button>
      </DialogActions>
    </Fragment>
  );
}

function ShowPercentage({
  percentageValue,
  state,
  isPositive = false,
}: {
  percentageValue: number;
  state: string;
  isPositive?: boolean;
}) {
  return (
    <Card sx={{ padding: 0, overflow: "hidden" }}>
      <Stack direction="row">
        <Box
          textAlign="center"
          padding={2}
          sx={{
            width: "10rem",
            backgroundColor: isPositive ? "#56BC72" : "#D62422",
          }}
        >
          {percentageValue.toFixed(2)}%
        </Box>
        <Box padding={2} textAlign="center" flex={1}>
          {state}
        </Box>
      </Stack>
    </Card>
  );
}

export const EfficiencyStatesScreen = () => {
  return (
    <Fragment>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={[
            "TwoWeeks",
            "Month",
            "ThreeMonths",
            "SixMonths",
            "Year",
            "Custom",
          ]}
        />
      </MachineViewNavBar>
      <EfficiencyStates />
    </Fragment>
  );
};
